import { render, staticRenderFns } from "./work-day.vue?vue&type=template&id=05f0a30e&scoped=true&"
import script from "./work-day.vue?vue&type=script&lang=js&"
export * from "./work-day.vue?vue&type=script&lang=js&"
import style0 from "./work-day.vue?vue&type=style&index=0&id=05f0a30e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f0a30e",
  null
  
)

export default component.exports